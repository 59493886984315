import * as React from 'react'
import { useModalContext } from 'src/design-system'

export const POSITION_MODAL_ID = 'position-modal'

export type PositionModalProps = {
  positionId: string
  disciplineId?: string
  source?: string
}

export const useOpenPositionModal = () => {
  const { openModal } = useModalContext()

  const openPositionModal = React.useCallback(
    (props: PositionModalProps) => {
      const { positionId, source } = props

      openModal<PositionModalProps>(POSITION_MODAL_ID, {
        positionId,
        source,
      })
    },
    [openModal]
  )

  return {
    openPositionModal,
  }
}
